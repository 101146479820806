import styled from 'styled-components'
import { breakpoints } from '../../styles/constants'

export const CounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.5em;
  background-color: #fff;
  cursor: pointer;
  min-width: 200px;
  min-height: 50px;
  border: 7px solid;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 1);

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.7rem;
  }
`

export const StateIcon = styled.span`
  display: inline-block;
  margin-right: 0.5em;
  font-size: 1.6em;
  line-height: 0;
  font-weight: 700;
  transform: translateY(1px);
`
