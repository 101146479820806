import styled from 'styled-components'
import Img from 'gatsby-image'

export const StyledEvent = styled.section`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
`

export const EventImage = styled(Img)`
  display: block;
  width: 100%;
  max-width: 1920px;
  height: auto;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
`

export const EventContent = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 50px;
`
