import React from 'react'
import {
  StyledEvent,
  EventContent,
  EventImage,
  ButtonWrapper,
  ImageWrapper,
} from './styles'
import InvitesCounter from '../InvitesCounter/InvitesCounter'
import { useBreakpoint } from '../BreakpointProvider'

const EventView = ({
  onClick,
  poster,
  posterVertical,
  event,
  isRegisteredForEvent,
}) => {
  const { aspectRatio } = posterVertical.local.childImageSharp.fluid
  const { windowAspectRatio } = useBreakpoint()

  const choosePoster = () =>
    aspectRatio > windowAspectRatio
      ? posterVertical.local.childImageSharp.fluid
      : poster.local.childImageSharp.fluid

  return (
    <>
      <StyledEvent>
        {poster && (
          <ImageWrapper>
            <EventImage fluid={choosePoster()} />
            <EventContent>
              {onClick && (
                <ButtonWrapper>
                  <InvitesCounter
                    onClick={onClick}
                    count={event && event.rsvpLeft}
                    isRegisteredForEvent={isRegisteredForEvent}
                    buttonText={event && event.rsvpButton}
                  />
                </ButtonWrapper>
              )}
            </EventContent>
          </ImageWrapper>
        )}
      </StyledEvent>
    </>
  )
}

export default EventView
