import { useEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
  const elem = document.getElementById('portal')
  const portalInner = document.createElement('div')
  useEffect(() => {
    elem.appendChild(portalInner)

    return () => {
      elem.removeChild(portalInner)
    }
  }, [elem, portalInner])

  return createPortal(children, portalInner)
}

export default Portal
