import React, { useRef } from 'react'
import Portal from '../Portal/Portal'
import { Overlay, ModalContent } from './styles'
import useClickOutside from '../../../hooks/useClickOutside'
import useFreezeBody from './useFreezeBody'
import useHandleEscapeClick from './useHandleEscapeClick'

const Modal = ({ open, onClose, trigger, children }) => {
  const refElem = useRef(null)

  useFreezeBody(open)
  useHandleEscapeClick(open, onClose)
  useClickOutside(refElem, onClose)

  return (
    <>
      {trigger}
      {open && (
        <Portal>
          <Overlay>
            <ModalContent ref={refElem}>{children}</ModalContent>
          </Overlay>
        </Portal>
      )}
    </>
  )
}

export default Modal
