import { useEffect } from 'react'

export default (open, cb) => {
  useEffect(() => {
    const handleClose = (e) => {
      if (e.keyCode === 27) {
        e.preventDefault()
        cb()
      }
    }

    if (open) {
      document.addEventListener('keydown', handleClose)
    }

    return () => {
      document.removeEventListener('keydown', handleClose)
    }
  })
}
