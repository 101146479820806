import { useEffect } from 'react'

export default (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = null
    }
  }, [open])
}
