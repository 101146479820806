import React from 'react'
import { CounterButton, StateIcon } from './styles'

const InvitesCounter = ({
  count,
  onClick,
  isRegisteredForEvent,
  buttonText = {},
}) => {
  const getButton = () => {
    if (isRegisteredForEvent) {
      const text = buttonText.registered || ` RSVP - You are on the list`
      return (
        <>
          <StateIcon role="img" aria-label={text} style={{ color: 'green' }}>
            &#9745;
          </StateIcon>
          {text}
        </>
      )
    }
    if (count <= -1) {
      const text = buttonText.unregistered || 'RSVP'
      return <>{text}</>
    }
    if (count === 0) {
      const text = buttonText.unavailable || 'RSVP - Registration completed'
      return (
        <>
          <StateIcon role="img" aria-label={text} style={{ color: 'red' }}>
            &#9746;
          </StateIcon>
          {text}
        </>
      )
    }
    const text = buttonText.unregistered || 'RSVP - Invites left:'
    return count ? <>{`${text} ${count}`}</> : <>{text}</>
  }
  return (
    <>
      <CounterButton onClick={onClick}>{getButton()}</CounterButton>
    </>
  )
}

export default InvitesCounter
