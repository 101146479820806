import { useEffect } from 'react'

const useClickOutside = (refElem, cb) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (refElem.current && !refElem.current.contains(e.target)) {
        cb(e)
      }
    }
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })
}

export default useClickOutside
