import styled from 'styled-components'

export const Overlay = styled.div`
  z-index: 200;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`

export const ModalContent = styled.div`
  padding: 1em;
  background-color: #fff;
`
